import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';

import { Close } from 'shared/icons';
import { ClipUploadStatus } from 'widgets/ClipUploader/ClipUploadStatus';

import 'react-circular-progressbar/dist/styles.css';
import style from './ClipUploadButton.module.scss';

interface IProps {
  currentFile: File;
  isError: boolean;
  pickHandle: () => void;
  percentage: number;
}

export const ClipUploadButton = ({
  currentFile,
  isError,
  pickHandle,
  percentage,
}: IProps): JSX.Element => (
  <button type="button" className={style.img} onClick={pickHandle}>
    <div className={style.audio}>
      {currentFile && !isError ? (
        <div className={style.progressBar}>
          <div className={style.close}>
            <Close />
          </div>
          <CircularProgressbar
            value={percentage}
            styles={{
              path: {
                stroke: '#6860F1',
              },
            }}
          />
        </div>
      ) : (
        <div className={style.status}>
          <ClipUploadStatus isError={isError} />
        </div>
      )}
    </div>
  </button>
);
