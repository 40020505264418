import { IResponseStats, IParams } from './types';
import { endpoints } from '../../../../shared/api/endpoints/endpoints';
import { mainApi } from '../config';

export const reportsApiQuery = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getMetricEventsByOwnerId: builder.query<IResponseStats, IParams>({
      query: (params) => ({
        url: `${endpoints.getStatsByOwner}`,
        params: params,
      }),
    }),
    getTotalReport: builder.query<
      IResponseStats,
      { start_date: string; end_date: string; owner_id?: number }
    >({
      query: (params) => ({
        url: `${endpoints.getTotalReport}`,
        params: params,
      }),
    }),
    getSummaryReport: builder.query<
      IResponseStats,
      { start_date: string; end_date: string; owner_id?: number }
    >({
      query: (params) => ({
        url: `${endpoints.getSummaryReport}`,
        params: params,
      }),
    }),
    getTBankReport: builder.query<
      IResponseStats,
      { start_date: string; end_date: string; owner_id: number }
    >({
      query: (params) => ({
        url: `${endpoints.getTBankReport}`,
        params: params,
      }),
    }),
    getAppleBankReport: builder.query<
      IResponseStats,
      { start_month: string; end_month: string; owner_id: number }
    >({
      query: (params) => ({
        url: `${endpoints.getAppleBankReport}`,
        params: params,
      }),
    }),
    getLicenseReport: builder.query<IResponseStats, { tmp_key: string }>({
      query: ({ tmp_key }) => ({
        url: `${endpoints.getLicenseReport}`,
        params: { tmp_key },
      }),
    }),
  }),
});

export const {
  useLazyGetMetricEventsByOwnerIdQuery,
  useLazyGetTotalReportQuery,
  useLazyGetSummaryReportQuery,
  useLazyGetAppleBankReportQuery,
  useLazyGetTBankReportQuery,
  useGetLicenseReportQuery,
} = reportsApiQuery;
