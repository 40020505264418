import { CreateButton } from 'widgets/CreateButton';

const UploadIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.092 3.00061H3.09204"
      stroke="#6860F1"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.97363 6.99998V16.9998"
      stroke="#6860F1"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.97363 7.00009L5.97372 11"
      stroke="#6860F1"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.97372 7.00009L13.9736 11"
      stroke="#6860F1"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const UploadSpreadsheet = ({
  onClick,
  disabled,
  description,
}: {
  onClick: () => void;
  disabled?: boolean;
  description?: string;
}): JSX.Element => (
  <CreateButton
    type="reverse"
    description={description || 'Загрузить таблицу'}
    icon={<UploadIcon />}
    onClick={onClick}
    disabled={disabled}
  />
);
