import { useFormikContext } from 'formik';
import {
  Dispatch,
  FC,
  SetStateAction,
  SyntheticEvent,
  useEffect,
  useMemo,
  useState,
  useTransition,
} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { OptionPlaylist } from './OptionPlaylist';
import { Playlist } from '../../../app/api/services/playlists/types';
import { Modal } from '../../../components/Modal';
import { Plus } from '../../../shared/icons';
import { SearchEntity } from '../../../store/slices/selectSlice';
import { ClickShowContainer } from '../../ClickShowContainer';
import { SelectWithOptions } from '../../SelectWithOptions';
import { Separator } from '../../Separator';
import { getTheIdArray } from 'utils';
import { Loader, ObligatoryField } from 'widgets';

import style from './AddPlaylistsWithScroll.module.scss';

interface IProps {
  searchEntity: SearchEntity;
  options: Playlist[];
  playlists: Playlist[];
  label: string;
  linkToEditEntity: string;
  isError?: boolean;
  setPlaylistsOffset: Dispatch<SetStateAction<number>>;
  totalCount: number;
  setAddedPlaylists: Dispatch<SetStateAction<number[]>>;
  field: string;
  setDeletedPlaylists: Dispatch<SetStateAction<number[]>>;
  isObligatory?: boolean;
  setPlaylists: Dispatch<SetStateAction<Playlist[]>>;
}

export const AddPlaylistsWithScroll: FC<IProps> = ({
  searchEntity,
  playlists,
  options,
  label,
  field,
  linkToEditEntity,
  isError = false,
  setPlaylistsOffset,
  setDeletedPlaylists,
  setAddedPlaylists,
  isObligatory,
  setPlaylists,
}) => {
  const [showPlaylistsSelect, setShowPlaylistsSelect] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const { setFieldValue } = useFormikContext();
  const [isPending, startTransition] = useTransition();

  const handleAddPlaylist = (e: SyntheticEvent) => {
    e.preventDefault();
    setShowPlaylistsSelect(true);
  };

  const handlePlaylistsSelect = (option) => {
    setSelectedOption(null);

    const isInArray = !!playlists.find((item) => item?.id === option?.id);

    if (isInArray || option === null) {
      return;
    } else {
      startTransition(() => {
        setAddedPlaylists((prevAddedPlaylists) => [
          ...prevAddedPlaylists,
          option.id,
        ]);
        setPlaylists((prevPlaylists) => [option, ...prevPlaylists]);
        setShowPlaylistsSelect(false);
      });
    }
  };

  const handleDeletePlaylist = (id: number) => {
    startTransition(() => {
      setPlaylists((prevPlaylists) =>
        prevPlaylists.filter((playlist) => playlist.id !== id)
      );

      setDeletedPlaylists((prevDeletedPlaylists) => [
        ...prevDeletedPlaylists,
        id,
      ]);
    });
  };

  const handleShowAll = () => {
    setModalOpen(true);
  };

  const handleScroll = () =>
    startTransition(() => setPlaylistsOffset((prev) => prev + 50));

  const memoizedPlaylists = useMemo(() => playlists.slice(0, 9), [playlists]);

  useEffect(() => {
    setFieldValue(field, getTheIdArray(playlists));
  }, [field, playlists, setFieldValue]);

  useEffect(() => {
    if (modalOpen) {
      document.body.classList.add('noscroll');
    } else {
      document.body.classList.remove('noscroll');
    }
  }, [modalOpen]);

  const renderOptions = (options) => (
    <div className={style.selectedOptions}>
      {options?.map((option) => (
        <OptionPlaylist
          handleDeletePlaylist={handleDeletePlaylist}
          linkToEditEntity={linkToEditEntity}
          option={option}
        />
      ))}
    </div>
  );

  return (
    <div className={style.container}>
      {isPending && <Loader />}
      <div className={style.header}>
        <div className={style.headerAndPlus}>
          <h3 className={isError ? style.labelError : style.label}>
            {label} {isObligatory && <ObligatoryField />}
          </h3>
          <button className={style.add} onClick={handleAddPlaylist}>
            <Plus />
          </button>
        </div>
      </div>

      <Separator />

      {showPlaylistsSelect && (
        <ClickShowContainer
          dropdown={showPlaylistsSelect}
          setDropdown={setShowPlaylistsSelect}
          className={style.select}
        >
          <SelectWithOptions
            searchEntity={searchEntity}
            options={options}
            onChange={handlePlaylistsSelect}
            value={selectedOption}
          />
        </ClickShowContainer>
      )}

      <div>
        <div className={style.selectedOptions}>
          {renderOptions(memoizedPlaylists)}
        </div>

        {playlists?.length >= 9 && (
          <button
            type="button"
            className={style.buttonShowAll}
            onClick={handleShowAll}
          >
            Показать все
          </button>
        )}

        <Modal active={modalOpen} setActive={setModalOpen} title="Плейлисты">
          <div id="scrollableDiv" className={style.modal}>
            <InfiniteScroll
              scrollableTarget="scrollableDiv"
              dataLength={playlists?.length ?? 0}
              hasMore={true}
              next={handleScroll}
              loader={false}
              className={style.scrollContainer}
            >
              {renderOptions(playlists)}
            </InfiniteScroll>
          </div>
        </Modal>
      </div>
    </div>
  );
};
