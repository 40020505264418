import { paramName } from './helpers';
import {
  IRequestToList,
  IRequestToReference,
  IResponseFilters,
  IResponseGetList,
  IResponseGetReference,
} from './types';
import { endpoints } from '../../../../shared/api/endpoints/endpoints';
import { tags } from '../../../../shared/api/tags/tags';
import { mainApi } from '../config';

export const filtersApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getFiltersForEntity: builder.query<IResponseFilters, string>({
      query: (entity) => ({
        url: `${endpoints.filters}?entity=${entity.replace('_', '-')}`,
        method: 'GET',
      }),
    }),
    getFiltredListByEntity: builder.mutation<IResponseGetList, IRequestToList>({
      query: ({ entity, body }) => ({
        url: `${endpoints.filters}?entity=${entity.replace('_', '-')}`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: [tags.filters],
    }),
    getFiltredListByReference: builder.query<
      IResponseGetReference,
      IRequestToReference
    >({
      query: ({ reference, name }) => ({
        url: `${reference}${paramName(name)}`,
        method: 'GET',
        params: { limit: 20 },
      }),
    }),
  }),
});

export const {
  useGetFiltersForEntityQuery,
  useGetFiltredListByEntityMutation,
  useGetFiltredListByReferenceQuery,
} = filtersApi;
