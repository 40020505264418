import { GoogleOAuthProvider } from '@react-oauth/google';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './app/App';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import AuthMiddleware from './components/Router/AuthMiddleware';
import { store } from './store';

import './main.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

Sentry.init({
  dsn: `https://${process.env.REACT_APP_SENTRY_DSN}@${process.env.REACT_APP_SENTRY_HOST}/6`,
  integrations: [new BrowserTracing({ tracingOrigins: ['*'] })],
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_APP_ENV,
});

root.render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_CMS_GOOGLE_CLIENT_ID}>
      <BrowserRouter>
        <ErrorBoundary>
          <AuthMiddleware>
            <App />
          </AuthMiddleware>
        </ErrorBoundary>
      </BrowserRouter>
    </GoogleOAuthProvider>
  </Provider>
);
