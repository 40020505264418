/* eslint-disable @typescript-eslint/no-explicit-any */
import cn from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';

import { Close, PlayIcon, StopIcon } from '../../../shared/icons';

import style from './AudioPlayer.module.scss';

interface IProps {
  clipUrl: string;
  isPlaying: boolean;
  changePlay: () => void;
  onClose: () => void;
  open: boolean;
  title: string;
  clipFile: File;
}

export const AudioPlayer = ({
  clipUrl,
  clipFile,
  isPlaying,
  changePlay,
  onClose,
  open,
  title,
}: IProps): JSX.Element => {
  const audioRef = useRef<HTMLAudioElement | any>(null);
  const progressBarRef = useRef<HTMLInputElement | any>(null);
  const playAnimationRef = useRef<any>(null);
  const [audioSource, setAudioSource] = useState<string | null>(null); // URL или blob URL

  const [timeProgress, setTimeProgress] = useState(0);
  const [duration, setDuration] = useState(0);

  const repeat = useCallback(() => {
    const currentTime = audioRef.current?.currentTime;
    setTimeProgress(currentTime);

    if (progressBarRef.current) {
      progressBarRef.current.value = currentTime;
      progressBarRef.current.style.setProperty(
        '--range-progress',
        `${(progressBarRef.current.value / duration) * 100}%`
      );
      progressBarRef.current.style.background = `linear-gradient(to right, #8179fe ${
        (progressBarRef.current.value / duration) * 100
      }%, #f4f5f8 ${(progressBarRef.current.value / duration) * 100}%)`;
    }

    playAnimationRef.current = requestAnimationFrame(repeat);
  }, [audioRef, duration, progressBarRef, setTimeProgress]);

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }

    if (audioRef.current && audioSource) {
      playAnimationRef.current = requestAnimationFrame(repeat);
    }
  }, [isPlaying, audioRef, repeat, audioSource]);

  useEffect(() => {
    if (clipFile) {
      setAudioSource(URL.createObjectURL(clipFile));
    } else if (clipUrl) {
      setAudioSource(clipUrl);
    }
  }, [clipUrl, clipFile]);

  const handleProgressChange = () => {
    audioRef.current.currentTime = progressBarRef.current.value;
  };

  const onLoadedMetadata = () => {
    const seconds = audioRef.current.duration;
    setDuration(seconds);
    progressBarRef.current.max = seconds;
  };

  const formatTime = (time) => {
    if (time && !isNaN(time)) {
      const minutes = Math.floor(time / 60);
      const formatMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
      const seconds = Math.floor(time % 60);
      const formatSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

      return `${formatMinutes}:${formatSeconds}`;
    }

    return '00:00';
  };

  return (
    <div className={cn(style.container, { [style.open]: open })}>
      <button
        className={style.closeButton}
        onClick={(e) => {
          e.preventDefault();
          onClose();
        }}
      >
        <Close />
      </button>
      <div className={style.naming}>
        <button
          className={style.playButton}
          onClick={(e) => {
            e.preventDefault();
            changePlay();
          }}
        >
          {isPlaying ? <StopIcon /> : <PlayIcon />}
        </button>
        <span className={style.title}>{title}</span>
      </div>
      <div className={style.progressBar}>
        <span className={style.time}>{formatTime(timeProgress)}</span>
        <input
          type="range"
          defaultValue={0}
          ref={progressBarRef}
          onChange={handleProgressChange}
        />
        <span className={style.time}>
          -{formatTime(duration - timeProgress)}
        </span>
      </div>
      <audio
        ref={audioRef}
        src={audioSource}
        onLoadedMetadata={onLoadedMetadata}
      />
    </div>
  );
};
