import classNames from 'classnames';
import React from 'react';

import style from './Table.module.scss';

export const Table = ({
  columns,
  rows,
  className,
}: {
  columns: string[];
  rows: string[][];
  className?: string;
}): JSX.Element => (
  <table className={classNames(className, style.table)}>
    <thead>
      <tr>
        {columns.map((column, index) => (
          <th key={index}>{column}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {rows.map((row, rowIndex) => (
        <tr key={rowIndex}>
          {columns.map((column, columnIndex) => (
            <td key={columnIndex}>{row[columnIndex] || ''}</td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);
