import React from 'react';

import { Delete } from 'shared/icons';
import { TextWithTooltip } from 'widgets/TextWithTooltip';

import style from './ClipAudioFile.module.scss';

interface IProps {
  shortFileName: string;
  deleteCurrentFileHandle: () => void;
}

export const ClipAudioFile = ({
  shortFileName,
  deleteCurrentFileHandle,
}: IProps): JSX.Element => (
  <>
    {shortFileName && (
      <span className={style.fileName}>
        <TextWithTooltip text={shortFileName} />
      </span>
    )}
    <button
      type="button"
      className={style.trashButton}
      onClick={deleteCurrentFileHandle}
    >
      <Delete fill="#434343" />
    </button>
  </>
);
