import React from 'react';
import { Link } from 'react-router-dom';

import { Router } from 'components';
import { Point, Trash } from 'shared/icons';
import { TextWithTooltip } from 'widgets/TextWithTooltip';

import style from './AddPlaylistsWithScroll.module.scss';

export const OptionPlaylist = ({
  option,
  linkToEditEntity,
  handleDeletePlaylist,
}: {
  option: { id: number; name: string };
  linkToEditEntity: string;
  handleDeletePlaylist: (id: number) => void;
}): JSX.Element => (
  <div key={option.id} className={style.option}>
    <div className={style.point}>
      <Point />
    </div>
    <Link
      className={style.name}
      to={`${linkToEditEntity}/${Router.Edit}/${option?.id}`}
    >
      {option?.name?.length > 80 ? (
        <TextWithTooltip text={option?.name} />
      ) : (
        <div>{option?.name}</div>
      )}
    </Link>
    <button
      type="button"
      onClick={() => handleDeletePlaylist(option.id)}
      className={style.deleteButton}
    >
      <Trash />
    </button>
  </div>
);
